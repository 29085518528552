<template>
  <div class="container-fluid pt-2">
    <b-tabs
      v-if="getScMode !== 'SC_DASHBOARD'"
      content-class="mt-3"
      v-model="activateTabIndex"
    >
      <b-tab title="Shared Workspaces" @click="handleTabChange(true)">
        <draggable
          class="row"
          ghost-class="ghost"
          v-model="manageSharedWorkspaceList"
          handle=".handle"
        >
          <div
            v-for="workspace in manageSharedWorkspaceList"
            :key="'shared_' + workspace.id"
            class="workspace-preview col-12 col-md-6 col-xl-3 mb-2"
          >
            <b-card class="card-workspace">
              <div class="card-overlay"></div>
              <div
                class="card-content"
                @click="navigateToSharedWorkspaceDetail(workspace.id, $event)"
              >
                <div
                  class="d-flex justify-content-between card-workspace-actions"
                >
                  <div
                    class="handle"
                    v-b-tooltip.hover
                    title="Drag to rearrange"
                  >
                    <i class="fa fa-bars"></i>
                  </div>
                  <div class="d-flex"></div>
                </div>

                <b-card-title class="d-flex align-items-center">
                  <span>{{ workspace.name }}</span>
                  <span v-if="workspace.is_default" class="ml-2"
                    ><small>(default)</small></span
                  >
                </b-card-title>

                <b-card-body class="p-0">
                  <grid-layout
                    :layout.sync="workspace.layout.panels"
                    :col-num="12"
                    :row-height="5"
                    :max-rows="12"
                    :is-draggable="false"
                    :is-resizable="false"
                    :vertical-compact="true"
                    :use-css-transforms="true"
                  >
                    <grid-item
                      v-for="item in workspace.layout.panels"
                      :static="true"
                      :x="item.x"
                      :y="item.y"
                      :w="item.w"
                      :h="item.h"
                      :i="item.i"
                      :key="'shared_grid_' + item.i"
                      autoSize="false"
                    >
                      <div class="p-2">
                        {{ getWorkspacePanelTitle(item.panel) }}
                      </div>
                    </grid-item>
                  </grid-layout>
                </b-card-body>
              </div>
            </b-card>
          </div>
        </draggable>
      </b-tab>
    </b-tabs>

    <b-tabs v-else content-class="mt-3" v-model="activateTabIndex">
      <b-tab title="Personal Workspaces" @click="handleTabChange(false)">
        <div class="d-flex justify-content-between mb-2">
          <div>
            <router-link
              :to="{ name: 'Workspace Create' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-button
                variant="success"
                size="sm"
                :href="href"
                @click="navigate"
                ><i class="fa fa-plus mr-1"></i>Add Workspace
              </b-button>
            </router-link>
          </div>
        </div>
        <draggable
          class="row"
          ghost-class="ghost"
          v-model="workspaceList"
          handle=".handle"
        >
          <div
            v-for="workspace in workspaceList"
            :key="workspace.id"
            class="workspace-preview col-12 col-md-6 col-xl-3 mb-2"
          >
            <b-card class="card-workspace">
              <div class="card-overlay"></div>
              <div
                class="card-content"
                @click="navigateToWorkspaceDetail(workspace.id, $event)"
              >
                <div
                  class="d-flex justify-content-between card-workspace-actions"
                >
                  <div
                    class="handle"
                    v-b-tooltip.hover
                    title="Drag to rearrange"
                  >
                    <i class="fa fa-bars"></i>
                  </div>
                  <div class="d-flex">
                    <div class="text-primary" v-if="workspace.is_default">
                      <i class="fa fa-check-circle"></i>
                    </div>
                    <div
                      class="btn-set-default"
                      v-b-tooltip.hover
                      title="Set as default Workspace"
                      @click="setDefaultWorkspace(workspace)"
                      v-else
                    >
                      <i class="far fa-check-circle"></i>
                    </div>
                    <!-- <router-link :to="{ name: 'Workspace Edit', params: { workspace_id: workspace.id }}" v-slot="{ href, route, navigate }" custom>
                                        <a :href="href" @click="navigate" class="mr-3" v-b-tooltip.hover title="Update Workspace"><i class="fa fa-edit"></i></a>
                                    </router-link> -->
                    <a
                      href="#"
                      class="text-danger ml-2"
                      @click="confirmDelete(workspace, $event)"
                      v-b-tooltip.hover
                      title="Delete Workspace"
                      v-if="!workspace.is_default"
                      ><i class="fa fa-trash-alt"></i
                    ></a>
                  </div>
                </div>

                <b-card-title class="d-flex align-items-center">
                  <span>{{ workspace.name }}</span>
                  <span v-if="workspace.is_default" class="ml-2"
                    ><small>(default)</small></span
                  >
                </b-card-title>

                <b-card-body class="p-0">
                  <grid-layout
                    :layout.sync="workspace.layout.panels"
                    :col-num="12"
                    :row-height="5"
                    :max-rows="12"
                    :is-draggable="false"
                    :is-resizable="false"
                    :vertical-compact="true"
                    :use-css-transforms="true"
                  >
                    <grid-item
                      v-for="item in workspace.layout.panels"
                      :static="true"
                      :x="item.x"
                      :y="item.y"
                      :w="item.w"
                      :h="item.h"
                      :i="item.i"
                      :key="'grid_' + item.i"
                      autoSize="false"
                    >
                      <div class="p-2">
                        {{ getWorkspacePanelTitle(item.panel) }}
                      </div>
                    </grid-item>
                  </grid-layout>
                </b-card-body>
              </div>
            </b-card>
          </div>
        </draggable>
      </b-tab>
      <b-tab
        title="Shared Workspaces"
        v-if="getScMode !== 'SC_DASHBOARD'"
        @click="handleTabChange(true)"
      >
        <div class="d-flex justify-content-between mb-2">
          <div>
            <router-link
              :to="{ name: 'Workspace Create Shared' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-button
                variant="success"
                size="sm"
                :href="href"
                @click="navigate"
                ><i class="fa fa-plus mr-1"></i>Add Shared Workspace
              </b-button>
            </router-link>
          </div>
        </div>
        <draggable
          class="row"
          ghost-class="ghost"
          v-model="manageSharedWorkspaceList"
          handle=".handle"
        >
          <div
            v-for="workspace in manageSharedWorkspaceList"
            :key="'shared_' + workspace.id"
            class="workspace-preview col-12 col-md-6 col-xl-3 mb-2"
          >
            <b-card class="card-workspace">
              <div class="card-overlay"></div>
              <div
                class="card-content"
                @click="navigateToSharedWorkspaceDetail(workspace.id, $event)"
              >
                <div
                  class="d-flex justify-content-between card-workspace-actions"
                >
                  <div
                    class="handle"
                    v-b-tooltip.hover
                    title="Drag to rearrange"
                  >
                    <i class="fa fa-bars"></i>
                  </div>
                  <div class="d-flex">
                    <!--                    <div class="text-primary" v-if="workspace.is_default">-->
                    <!--                      <i class="fa fa-check-circle"></i>-->
                    <!--                    </div>-->
                    <!--                    <div-->
                    <!--                        class="btn-set-default"-->
                    <!--                        v-b-tooltip.hover-->
                    <!--                        title="Set as default Workspace"-->
                    <!--                        @click="setDefaultWorkspace(workspace)"-->
                    <!--                        v-else-->
                    <!--                    >-->
                    <!--                      <i class="far fa-check-circle"></i>-->
                    <!--                    </div>-->
                    <!-- <router-link :to="{ name: 'Workspace Edit', params: { workspace_id: workspace.id }}" v-slot="{ href, route, navigate }" custom>
                                        <a :href="href" @click="navigate" class="mr-3" v-b-tooltip.hover title="Update Workspace"><i class="fa fa-edit"></i></a>
                                    </router-link> -->
                    <a
                      href="#"
                      class="text-danger ml-2"
                      @click="confirmDelete(workspace, $event)"
                      v-b-tooltip.hover
                      title="Delete Workspace"
                      v-if="!workspace.is_default"
                      ><i class="fa fa-trash-alt"></i
                    ></a>
                  </div>
                </div>

                <b-card-title class="d-flex align-items-center">
                  <span>{{ workspace.name }}</span>
                  <span v-if="workspace.is_default" class="ml-2"
                    ><small>(default)</small></span
                  >
                </b-card-title>

                <b-card-body class="p-0">
                  <grid-layout
                    :layout.sync="workspace.layout.panels"
                    :col-num="12"
                    :row-height="5"
                    :max-rows="12"
                    :is-draggable="false"
                    :is-resizable="false"
                    :vertical-compact="true"
                    :use-css-transforms="true"
                  >
                    <grid-item
                      v-for="item in workspace.layout.panels"
                      :static="true"
                      :x="item.x"
                      :y="item.y"
                      :w="item.w"
                      :h="item.h"
                      :i="item.i"
                      :key="'shared_grid_' + item.i"
                      autoSize="false"
                    >
                      <div class="p-2">
                        {{ getWorkspacePanelTitle(item.panel) }}
                      </div>
                    </grid-item>
                  </grid-layout>
                </b-card-body>
              </div>
            </b-card>
          </div>
        </draggable>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import draggable from "vuedraggable";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import { GridLayout, GridItem } from "vue-grid-layout";

let utils = require("@/assets/global/js/utils.js");

export default {
  name: "WorkspaceList",
  components: {
    GridLayout,
    GridItem,
    draggable
  },
  data: function () {
    return {
      userID: "",
      panelOptions: [],
      activateTabIndex: 0
    };
  },
  computed: {
    ...mapGetters({
      isWorkspacesInitiated: "psim/isWorkspacesInitiated",
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",
      getScMode: "session/getScMode"
    }),
    workspaceList: {
      get() {
        var workspaces = this.$store.getters["psim/getWorkspaces"];
        return workspaces;
      },
      set(value) {
        var workspace_arrangement = [];
        for (var i in value) {
          workspace_arrangement.push({ id: value[i].id, index: parseInt(i) });

          value[i].i = parseInt(i);
          this.$store.dispatch("psim/updateWorkspace", {
            id: value[i].id,
            data: { index: parseInt(i) }
          });
          this.$store.dispatch("psim/updateWorkspaceIndex", {
            id: value[i].id,
            index: parseInt(i)
          });
        }
        this.rearrangeWorkspaces(workspace_arrangement);
      }
    },
    manageSharedWorkspaceList: {
      get() {
        var workspaces = this.$store.getters["psim/getManageSharedWorkspaces"];
        return workspaces;
      },
      set(value) {
        var workspace_arrangement = [];
        for (var i in value) {
          workspace_arrangement.push({ id: value[i].id, index: parseInt(i) });

          value[i].i = parseInt(i);
          this.$store.dispatch("psim/updateManageSharedWorkspace", {
            id: value[i].id,
            data: { index: parseInt(i) }
          });
          this.$store.dispatch("psim/updateManageSharedWorkspaceIndex", {
            id: value[i].id,
            index: parseInt(i)
          });

          this.$store.dispatch("psim/updateSharedWorkspace", {
            id: value[i].id,
            data: { index: parseInt(i) }
          });
          this.$store.dispatch("psim/updateSharedWorkspaceIndex", {
            id: value[i].id,
            index: parseInt(i)
          });
        }
        this.rearrangeSharedWorkspaces(workspace_arrangement);
      }
    }
  },
  watch: {
    isWorkspacesInitiated: {
      handler: function (n, o) {
        if (n === true) {
          this.initView();
        }
      },
      deep: true
    },
    $route: {
      handler(route) {
        if (route.query.shared !== undefined) {
          if (route.query.shared) {
            this.activateTabIndex = 1;
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted: function () {
    this.initView();
    this.fetchPanels();
  },
  methods: {
    initView: function () {
      // console.log("Viewing SettingsWorkspace - WorkspaceList");
      var $this = this;
      $this.$parent.pageTitle = "Manage Workspaces";

      // $this.workspaceList = this.$store.getters["psim/getWorkspaces"];
      // console.log($this.workspaceList)
    },
    fetchPanels: function () {
      var $this = this;
      var current_user = $this.$store.getters["session/getCurrentUser"];
      var available_panel_options =
        $this.$store.getters["psim/getPanelOptions"];
      $this.panelOptions = [];

      if (available_panel_options.length > 0) {
        for (var d in available_panel_options) {
          var panel_data = {
            value: available_panel_options[d].id,
            text: available_panel_options[d].name
          };
          $this.panelOptions.push(panel_data);
        }
      }
    },
    getWorkspacePanelTitle: function (panel) {
      var panel_option = this.panelOptions.filter(function (item) {
        if (item.value == panel) {
          return true;
        }
        return false;
      });

      if (panel_option.length > 0) {
        return panel_option[0].text;
      }
      return panel;
    },
    confirmDelete: function (workspace, e) {
      e.preventDefault();
      var $this = this;
      var workspace_id = workspace.id;
      if (workspace.is_default == true) {
        this.$bvModal.msgBoxOk(
          "Cannot delete " +
            workspace.name +
            " because it is your default Workspace.",
          { centered: true }
        );
      } else {
        this.$bvModal
          .msgBoxConfirm("Confirm deleting Workspace " + workspace.name + "?", {
            centered: true
          })
          .then((value) => {
            if (value == true) {
              var data = {
                previous_data: {}
              };
              var API_URL =
                $this.getAPIServerURL + "/workspaces/" + workspace_id + "/";
              const client = $this.$root.getAjaxFetchClient();
              client
                .deleteRequest(API_URL, data)
                .then((result) => {
                  this.$store.dispatch("psim/deleteWorkspace", workspace_id);
                  this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text: "Deleted workspace successfully",
                    message_type: "success"
                  });

                  // TOCLEAN
                  // } else {
                  //     this.$store.dispatch('session/addGlobalAlertMessage', {"message_text": result.detail, "message_type": "danger"});
                  // }
                })
                .catch((err) => {
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text: err.detail,
                    message_type: "danger"
                  });
                  // if (err.status == 401) {
                  //   $this.$store.dispatch("session/logoutSession");
                  // }
                });
            }
          })
          .catch((err) => {
            // An error occurred
          });
      }
    },
    rearrangeSharedWorkspaces: function (arrangement) {
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/workspaces/workspaces-index/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, { workspace_ids: arrangement })
        .then((data) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Updated Workspace sequence successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    rearrangeWorkspaces: function (arrangement) {
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/workspaces/workspaces-index/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, { workspace_ids: arrangement })
        .then((data) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Updated Workspace sequence successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    setDefaultWorkspace: function (workspace) {
      var $this = this;
      var API_URL =
        $this.getAPIServerURL + "/workspaces/" + workspace.id + "/set-default/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL)
        .then((data) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Changed default workspace successfully",
            message_type: "success"
          });

          var GET_API_URL = $this.getAPIServerURL + "/workspaces/";
          client
            .getRequest(GET_API_URL)
            .then((workspace_data) => {
              if (workspace_data != null) {
                for (var d in workspace_data.result) {
                  var w_data = {
                    id: workspace_data.result[d].id,
                    name: workspace_data.result[d].name,
                    index: workspace_data.result[d].index,
                    layout: workspace_data.result[d].layout,
                    is_default: workspace_data.result[d].is_default
                  };
                  $this.$store.dispatch("psim/addWorkspace", w_data);

                  if (w_data.is_default == true) {
                    $this.$store.dispatch("psim/setSelectedWorkspace", w_data);
                  }
                }
              }
            })
            .catch((err) => {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: err.detail,
                message_type: "danger"
              });
              // if (err.status == 401) {
              //   $this.$store.dispatch("session/logoutSession");
              // }
            });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    handleTabChange(shared) {
      this.$router
        .push({
          name: "Workspace List",
          params: { shared: shared }
        })
        .catch(() => {});
    },
    navigateToWorkspaceDetail: function (workspace_id, e) {
      // console.log("Clicked Card", e.target.className);
      if (e.target.className == "fa fa-edit") {
        //
      } else if (e.target.className == "fa fa-trash-alt") {
        //
      } else if (e.target.className == "handle") {
        //
      } else if (e.target.className == "fa fa-check-circle") {
        //
      } else if (e.target.className == "far fa-check-circle") {
        //
      } else {
        this.$router
          .push({
            name: "Workspace Edit",
            params: { workspace_id: workspace_id }
          })
          .catch(() => {});
      }
    },
    navigateToSharedWorkspaceDetail: function (workspace_id, e) {
      // console.log("Clicked Card", e.target.className);
      if (e.target.className == "fa fa-edit") {
        //
      } else if (e.target.className == "fa fa-trash-alt") {
        //
      } else if (e.target.className == "handle") {
        //
      } else if (e.target.className == "fa fa-check-circle") {
        //
      } else if (e.target.className == "far fa-check-circle") {
        //
      } else {
        this.$router
          .push({
            name: "Workspace Edit Shared",
            params: { workspace_id: workspace_id }
          })
          .catch(() => {});
      }
    }
  }
};
</script>

<style lang="scss">
@import "./SettingsWorkspace.scss";
</style>
